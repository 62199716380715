import React from 'react';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import { Typography, List, ListItem, ListItemIcon, ListItemText } from '@mui/material';
import ArrowRightIcon from '@mui/icons-material/ArrowRight';
import { HeadingOne } from '../../common/HeadingOne';
import { ButtonOne } from '../../common/ButtonOne';
import { useNavigate } from 'react-router-dom';

const Container = styled(Box)((props) =>({
  backgroundColor: props.theme.palette.secondary.main,
  marginTop: '20px',
}));

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.secondary.main,
  color: theme.palette.white.main,
  ...theme.typography.body2,
  padding: theme.spacing(1),
  paddingLeft: theme.spacing(5),
  textAlign: 'left',
  boxShadow: 'none',
}));

// Styled component for the left grid image
const LeftImage = styled('img')({
  maxWidth: '100%',
  height: 'auto',
  display: 'block',
});

const BannerListItem = ({itemTxt}) => {
  return (
    <ListItem sx={{ padding: 0, alignItems: 'center', mb: 1.5}}>
      <img src="../list.svg" style={{width: '15px', marginRight: '8px'}}  />
      <ListItemText sx={{ margin: 0 ,}}
        primary={
          <Box sx={{ typography: 'body2',fontSize: '18px',fontFamily: 'Product-Sans-Regular',  }}>
            {itemTxt}
          </Box>
        }
      />
    </ListItem>
  )
}

const CenteredImage = styled('img')(({ theme }) => ({
  maxWidth: '100%',
  height: 'auto',
  margin: '0 auto',
  display: 'block',
  [theme.breakpoints.up('md')]: {
    maxWidth: '100%', // Adjust the image size for larger screens
    height: 'auto', // Ensure the image maintains its aspect ratio
  },
}));

const AllYouNeedItem = ({imgSrc, title, sx}) => {
  return (
    <Grid item xs={6} sm={3} sx={sx}>
      <Box sx={{backgroundColor: '#FB4747', padding: '20px 10px', borderRadius: '10px'}}>
        <Box sx={{ height: '53px', mb: 1}}>
          <CenteredImage src={imgSrc} alt={title} />
        </Box>
        
        <Typography variant="body2" align="center" sx={{ color: '#fff' }}>
          {title}
        </Typography>
      </Box>
    </Grid>
  )
}

export const HomeContainer4 = () => {
  const navigate = useNavigate();
  const navigateToURL = (url) => {
    navigate(url, { replace: true });
  }
  return (
    <Box py={15} sx={{backgroundColor: '#f5f5f5', '@media (max-width: 540px)': {py: 7, px: 3, textAlign: 'center'}, '@media (max-width: 1199px)': {px: 3,}}}>
      <Grid container justifyContent={'space-between'} maxWidth={1170} marginX={'auto'}>
        <Grid item xs={12} md={12} lg={6}>
          <HeadingOne color="secondary" sx={{fontSize: '2.5rem',fontFamily: 'Adieu-Regular', color: '#161526', '@media (max-width: 540px)': {fontSize: '1.75rem'}}} text="All you need is" />

          <Grid container spacing={1} sx={{ mt: 4 }}>
            <AllYouNeedItem imgSrc="./ninjaCaster.png" title="Ninja Caster"
              sx={{ paddingLeft: '0px' }} />
            <AllYouNeedItem imgSrc="./tripod.png" title="Camera & a tripod" />
            <AllYouNeedItem imgSrc="./power-bank.png" title="Powerbank" 
              sx={{ marginTop: { xs: '20px', sm: 0 } }} />
            <AllYouNeedItem imgSrc="./live.png" title="Internet" 
              sx={{ marginTop: { xs: '20px', sm: 0 } }}/>
          </Grid>
          
          <Typography variant="body2" sx={{ mt: 3, mb: 5}}>
            <List>
              <BannerListItem itemTxt="No expensive hardware or setup required" />
              <BannerListItem itemTxt="No technical expertise needed" />
              <BannerListItem itemTxt="No complex steps involved" />
            </List>
          </Typography>

          <ButtonOne text="View Product" onClick={() => navigateToURL(`/product`)} style={{marginRight: '10px', backgroundColor: "#FB4747", color: "#fff", height: "55px", fontSize: '16px'}} />
        </Grid>
        <Grid item xs={12} md={12} lg={6} sx={{position:'absolute', width:'100%', height:'100%',left:'50%', overflow:'hidden', '@media (max-width: 1199px)': {mt: 5, position:'relative', left:'0%'}}}>
          <Box component="img" src="./Ninja-Caster-new.webp" alt="Sample" sx={{width: '110%', position:'absolute', right:'5%', '@media (max-width: 1199px)': {position:'relative', right:'12%', top:'5%', width:'100%'} }} />
        </Grid>
        
      </Grid>
    </Box>
  );
}
