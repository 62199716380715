import * as React from 'react';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import { Typography, List, ListItem, ListItemIcon, ListItemText,Button, useTheme } from '@mui/material';
import ArrowRightIcon from '@mui/icons-material/ArrowRight';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import Stack from '@mui/material/Stack';
import Divider from '@mui/material/Divider';
import { ButtonOne } from '../../common/ButtonOne';
import BannerListItem from '../../common/BannerListItem';
import { useNavigate } from 'react-router-dom';
import { HeadingOne } from '../../common/HeadingOne';
import BackgroundVideo from '../../common/BackgroundVideo';
import VideoFrame from '../../common/VideoFrame';

const Item = styled(Paper)(({ theme }) => ({
  boxShadow: 'none',
}));

const Container = styled(Box)({
  flexGrow: 1,
  paddingBlock: 25
});

export const HomeContainer1 = () => {
  const theme = useTheme();
  const navigate = useNavigate();

  const navigateToURL = (url) => {
    navigate(url, { replace: true });
  }

  const streamToImgsStyle = {
    height: '30px'
  }
  const streamBox = {
    padding: '40px 30px',
    backgroundColor: '#f5f5f5',
    borderRadius: '10px',

  }

  return (
    <Box>
      <Grid container maxWidth={1170} marginX={'auto'} sx={{marginTop: '97px', paddingTop: '100px', '@media (max-width: 1199px)': {paddingTop: '50px', px: 3}} }>
        {/* <BackgroundVideo /> */}
        <Grid item xs={12} md={6} sx={{'@media (max-width: 540px)': {textAlign: 'center'}}}>
          <HeadingOne color="secondary" sx={{fontSize: '2.8rem',fontFamily: 'Adieu-Regular', '@media (max-width: 540px)': {fontSize: '2rem', mb: 5}}} text="Easiest way to Live Stream your Cricket Matches with Score Overlays" />
          <Box>
          <List sx={{marginTop: '20px'}}>
              <ListItem sx={{ padding: 0, alignItems: 'center', marginBottom: '10px' }}>
                <img src="../list.svg" style={{width: '15px', marginRight: '8px'}}  />
                <ListItemText sx={{ margin: 0, }}
                  primary={
                    <Typography variant="body2" component="span" sx={{fontSize: '18px',fontFamily: 'Product-Sans-Regular',color: '#161526','@media (max-width: 540px)': {fontSize: '1rem'}}}>
                      Go live in a minute!{' '}
                      <Typography variant="body2" component="span" color="secondary" sx={{fontSize: '18px',fontFamily: 'Product-Sans-Bold-Italic','@media (max-width: 540px)': {fontSize: '1rem'}}}>
                        Three, Two, One, Live!
                      </Typography>
                    </Typography>
                  }
                />
              </ListItem>

              <ListItem sx={{ padding: 0, alignItems: 'center', marginBottom: '10px' }}>
                <img src="../list.svg" style={{width: '15px', marginRight: '8px'}}  />
                <ListItemText sx={{ margin: 0, }}
                  primary={
                    <Typography variant="body2" component="span" color="primary" sx={{fontSize: '18px',fontFamily: 'Product-Sans-Regular','@media (max-width: 540px)': {fontSize: '1rem'}}}>
                      High-Quality video with zoom into pitch and ball-following.
                    </Typography>
                  }
                />
              </ListItem>
              
              <ListItem sx={{ padding: 0, alignItems: 'center', marginBottom: '10px' }}>
                <img src="../list.svg" style={{width: '15px', marginRight: '8px'}}  />
                <ListItemText sx={{ margin: 0 ,}}
                  primary={
                    <Typography variant="body2" component="span" color="secondary" sx={{fontSize: '18px',fontFamily: 'Product-Sans-Bold-Italic','@media (max-width: 540px)': {fontSize: '1rem'}}}>
                    Stream like a pro!{' '}  
                      <Typography variant="body2" component="span" color="primary" sx={{fontSize: '18px',fontFamily: 'Product-Sans-Regular','@media (max-width: 540px)': {fontSize: '1rem'}}}>
                          No computer required, fully wireless!
                      </Typography>
                    </Typography>
                  }
                />
              </ListItem>
              <ListItem sx={{ padding: 0, alignItems: 'center', marginBottom: '10px' }}>
                <img src="../list.svg" style={{width: '15px', marginRight: '8px'}}  />
                <ListItemText sx={{ margin: 0, }}
                  primary={
                    <Typography variant="body2" component="span" color="primary" sx={{fontSize: '18px',fontFamily: 'Product-Sans-Regular','@media (max-width: 540px)': {fontSize: '1rem'}}}>
                      Compatible with any camera.
                    </Typography>
                  }
                />
              </ListItem>
              <ListItem sx={{ padding: 0, alignItems: 'center', marginBottom: '10px' }}>
                <img src="../list.svg" style={{width: '15px', marginRight: '8px'}}  />
                <ListItemText sx={{ margin: 0, }}
                  primary={
                    <Typography variant="body2" component="span" color="primary" sx={{fontSize: '18px',fontFamily: 'Product-Sans-Regular','@media (max-width: 540px)': {fontSize: '1rem'}}}>
                      Inbuilt Cricket Overlays Graphics powered by CricClubs.
                    </Typography>
                  }
                />
              </ListItem>
              <ListItem sx={{ padding: 0, alignItems: 'center', marginBottom: '10px' }}>
                <img src="../list.svg" style={{width: '15px', marginRight: '8px'}}  />
                <ListItemText sx={{ margin: 0, }}
                  primary={
                    <Typography variant="body2" component="span" color="primary" sx={{fontSize: '18px',fontFamily: 'Product-Sans-Regular','@media (max-width: 540px)': {fontSize: '1rem'}}}>
                     All you need for live streaming in a simple easy to carry box.
                    </Typography>
                  }
                />
              </ListItem>
              <ListItem sx={{ padding: 0, alignItems: 'center', }}>
                <img src="../list.svg" style={{width: '15px', marginRight: '8px'}}  />
                <ListItemText sx={{ margin: 0, }}
                  primary={
                    <Typography variant="body2" component="span" color="primary" sx={{fontSize: '18px',fontFamily: 'Product-Sans-Regular','@media (max-width: 540px)': {fontSize: '1rem'}}}>
                      Great visibility for sponsors. Enhanced engagement for fans.
                    </Typography>
                  }
                />
              </ListItem>
            </List>
          </Box>
          <Box sx={{ display: 'block', justifyContent: 'space-between', mt: 4 }}>
            <ButtonOne text="Contact Us" onClick={() => navigateToURL(`/contact`) } style={{marginRight: '10px', backgroundColor: "#FB4747", color: "#fff", height: "55px", fontSize: '16px'}} />
            <Button
              color="primary"
              style={{ textTransform: 'capitalize', backgroundColor: 'transparent', border: 'none', marginLeft: '20px', fontSize: '16px' }}
              endIcon={<KeyboardArrowRightIcon style={{marginLeft: '-3px'}} />}
              onClick={() => navigateToURL(`/product`)}
            >
              View Product
            </Button>
          </Box>
        </Grid>
        <Grid item xs={12} md={6} sx={{textAlign: 'right', position:'relative', width:'100%', height:'100%',left:'0%', overflow:'hidden',  '@media (max-width: 1199px)': {mt: 5}, '@media (max-width: 899px)': {position:'relative', left:'0%'}}}>
          <Box component="img"  src="../Full_Kit.webp" sx={{width: '120%', position:'relative', '@media (max-width: 899px)': {position:'relative', right:'0%', top:'10%', width:'110%'} }}  />
        </Grid>
      </Grid>
      <Grid container justifyContent={'center'} alignItems={'center'} maxWidth={1170} marginX={'auto'} pt={8} mb={15} sx={{'@media (max-width: 540px)': {mb: 7}}}>
        <Grid item xs={12} md={7} style={streamBox} sx={{ textAlign: 'center'}}>
          <HeadingOne color="primary" sx={{fontSize: '1.9rem',fontFamily: 'Adieu-Regular', marginBottom: '30px', '@media (max-width: 540px)': {fontSize: '1.3rem'}}} text="Stream to any Platform in Minutes" />
          <Box>
            <Stack 
              direction="row" spacing={1} 
              justifyContent="center"
              alignItems="center"
              divider={<Divider orientation="vertical" flexItem />}
              sx={{ display: 'inline-flex', '@media (max-width: 540px)': {flexWrap: 'wrap', gridGap: '20px'}}}>
                <Item><img src="../youTube.png" alt="Logo 1" style={streamToImgsStyle} /></Item>
                <Item><img src="../CC.png" alt="Logo 2" style={streamToImgsStyle} /></Item>
                <Item><img src="../fb.png" alt="Logo 3" style={streamToImgsStyle} /></Item>
            </Stack>
          </Box>
          {/* <div class="background-video-frame-container">
          
            <video autoplay="" loop="" class="background-video-frame">
              <source src="/NinjaCaster-Website_2.mp4" type="video/mp4" />
                </video>
        
          </div> */}
          <VideoFrame />

        </Grid>
      </Grid>
    </Box>
  );
}