import * as React from 'react';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import Divider from '@mui/material/Divider';
import { ButtonOne } from '../../common/ButtonOne';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import { Carousel } from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import { Button, useTheme } from '@mui/material';
import { useLocation, useNavigate } from 'react-router-dom';
import { HeadingOne } from '../../common/HeadingOne';
import './index.css';

const Container = styled(Box)({
  flexGrow: 1,
  paddingBlock: 25
});

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: 'left',
  boxShadow: 'none',
}));

function generateCardContent(index, setActiveTab, imageSrc, title, description, setActiveDescription, bgColor, textColor, activeTab, viewPricingHandler) {
  return (
    <Grid
      p={'15px'}
      item lg={4}
      sm={12}
      md={4}
      sx={{ '@media (max-width: 540px)': { px: 0 } }}
    >
      <Box
        onClick={() => {
          setActiveTab(index)
          setActiveDescription(description)
        }}
        sx={{
          position: 'relative',
          backgroundColor: bgColor || 'primary.main',
          color: textColor || 'black',
          padding: '30px 40px',
          borderRadius: '16px',
          textAlign: 'center',
          minHeight: '450px',
          '@media (max-width: 1024px)': { padding: '20px' },
          '@media (max-width: 1024px)': { minHeight: 'auto' },
        }}
      >
        <img src={imageSrc} style={{ maxWidth: '100%' }} />
        <h2 style={{ fontFamily: 'Adieu-Regular', fontWeight: 'normal' }}>{title}</h2>
        <ul style={{ textAlign: 'left', paddingLeft: '20px' }}>
          {description.map((point, index) => (
            <li key={index} style={{ lineHeight: '22px', marginTop: '10px' }}>{point}</li>
          ))}
        </ul>

        <Box marginTop={'40px'}></Box>
        <Button
          variant="contained"
          color={index === activeTab ? 'inherit' : 'secondary'}
          onClick={(e) => {
            e.stopPropagation();
            setActiveTab(index)
            setActiveDescription(description)
            viewPricingHandler();
          }}
          sx={{
            position: 'absolute',
            bottom: '20px',
            left: '50%',
            transform: 'translateX(-50%)',
            textTransform: 'capitalize',
            fontFamily: 'Product-Sans-Regular',
            fontSize: '16px',
            padding: '10px 20px',
            backgroundColor: index === activeTab ? 'white' : 'secondary.main',
            color: index === activeTab ? 'secondary.main' : 'white',
            ':hover': {
              backgroundColor: index === activeTab ? '#f5f5f5' : 'secondary.dark',
            },
            '@media (max-width: 540px)': {
              fontSize: '14px',
              padding: '8px 16px',
            },
          }}
        >
          View Pricing
        </Button>
      </Box>
    </Grid>
  );
}

const ProductContainer4 = React.forwardRef((props, ref) => {
  const theme = useTheme();
  const navigate = useNavigate();
  const [activeTab, setActiveTab] = React.useState(1);
  const location = useLocation();
  const pricingRef = React.useRef(null)


  const card1Content = [
    'In-built High-quality display - See and adjust the exact video that will go to live stream in real-time.',
    'HDMI Input - Camera connects directly to Ninja Caster ensuring the highest quality of video.',
    'Wifi 6 / Sim card - Ensures full speed of hotspot of data connection is used to go live.',
  ];

  const card2Content = [
    'Ninja Caster',
    'Sony CX 405 HD camera.',
    'USB Mic for commentary',
    'Powerbank that can support 20 hours of streaming.',
    'Power cables and HDMI wire.',
    'Tripod',
    'Easy to carry, sturdy suitcase.'
  ];

  const card3Content = [
    'Get Ninja Caster shipped.',
    'Expert support for setup.',
    'Configuration of CricClubs overlays for the tournament.',
    'Setting up sponsor marketing in live-streaming.',
  ];

  const [activeDescription, setActiveDescription] = React.useState(card1Content);
  const products = {
    1: {
      name: 'Ninja Caster',
      price: '$799',
      offerPrice: '$599'
    },
    2: {
      name: 'Ninja Caster Kit',
      price: '$1,399',
      offerPrice: '$1,199'
    },
    3: {
      name: 'Ninja Caster Rentals',
      price: '$600/week',
      offerPrice: '$500/week'
    }
  }

  const images = [
    // './c-tripodbig.png',
    // './c-charger.png',
    // './c-wires.png',
    // './c-printer.png',
    // './c-box.png',
    './Ninja-Caster-on-Tripod-1.png',
    './NInja-Caster-in-a-box-1.png',
  ];
  const InfoListItem = ({ label, content }) => {
    const theme = useTheme();

    return (
      <Grid container alignItems="flex-start" mb={3}>
        <Grid item xs={12} md={3}>
          <Typography variant="body2" sx={{ fontFamily: 'Product-Sans-Bold', fontSize: '18px' }}>
            {label}
          </Typography>
        </Grid>
        <Grid item xs={12} md={9} pl={2} sx={{ '@media (max-width: 899px)': { pl: 0, mt: 0.4 } }}>
          <Typography variant="body2" sx={{ fontFamily: 'Product-Sans-Regular', fontSize: '18px' }}>
            {content}
          </Typography>
        </Grid>
      </Grid>
    );
  };

  const navigateToURL = (url) => {
    navigate(url, { replace: true });
  }

  const onViewPricingClickHandler = () => {
    if (pricingRef.current) {
      const rect = pricingRef.current.getBoundingClientRect();
      
      const offset = 100;
      const targetScrollPosition = window.scrollY + rect.top - offset;
  
      window.scrollTo({
        top: targetScrollPosition,
        behavior: 'smooth',
      });
    }
  };
  

  return (
    <>
      <Grid container maxWidth={1200} marginX={'auto'} sx={{ py: 15, '@media (max-width: 1199px)': { px: 3, py: 9 }, '@media (max-width: 540px)': { py: 7 } }} ref={ref}>
        {/* Card 1 */}
        {generateCardContent(
          1,
          setActiveTab,
          (activeTab == 1) ? './tablet1.png' : './tablet.png',
          'Ninja Caster',
          card1Content,
          setActiveDescription,
          (activeTab == 1) ? 'secondary.main' : '#f5f5f5',
          (activeTab == 1) ? 'white.main' : null,
          activeTab,
          onViewPricingClickHandler
        )}
        {/* Card 2 */}
        {generateCardContent(
          2,
          setActiveTab,
          (activeTab == 2) ? './camera.png' : './camera1.png',
          'Ninja Caster Kit',
          card2Content,
          setActiveDescription,
          (activeTab == 2) ? 'secondary.main' : '#f5f5f5',
          (activeTab == 2) ? 'white.main' : null,
          activeTab,
          onViewPricingClickHandler
        )}
        {/* Card 3 */}
        {generateCardContent(
          3,
          setActiveTab,
          (activeTab == 3) ? './camera.png' : './camera1.png',
          'Ninja Caster Rentals',
          card3Content,
          setActiveDescription,
          (activeTab == 3) ? 'secondary.main' : '#f5f5f5',
          (activeTab == 3) ? 'white.main' : null,
          activeTab,
          onViewPricingClickHandler
        )}
      </Grid>
      <Box ref={pricingRef}>
        <Grid container maxWidth={1220} marginX={'auto'} sx={{ '@media (max-width: 1199px)': { px: 3, } }} >
          <Grid item p={'25px'} lg={6} sx={{ '@media (max-width: 540px)': { p: 0 } }}>
            {
              activeTab == 1 && (
                <img src="./ORH8VX12.png" alt="Sample" style={{ width: '100%' }} />
              )
            }
            {
              activeTab != 1 && (
                <Carousel
                  showThumbs={true}
                  showIndicators={false}
                  na
                  showStatus={false}
                  infiniteLoop
                  className='image-carousel'
                >
                  {images.map((imgSrc, index) => (
                    <div key={index}>
                      <img src={imgSrc} style={{ maxWidth: '200px' }} />
                    </div>
                  ))}
                </Carousel>
              )
            }

          </Grid>
          <Grid item p={'25px'} lg={6} sx={{ '@media (max-width: 540px)': { p: 0 } }}>
            <Item>
              <HeadingOne color="secondary" sx={{ fontSize: '2.3rem', fontWeight: 'normal', mb: 1, fontFamily: 'Adieu-Regular', '@media (max-width: 540px)': { fontSize: '1.8rem' } }} text={products[activeTab].name} />
              <Typography variant="h5" gutterBottom color="secondary" sx={{ fontFamily: 'Product-Sans-Regular' }}>
                <ins style={{ textDecoration: 'none' }}>
                  {products[activeTab].offerPrice}
                </ins> <del style={{ color: theme.palette.grayShade.main, fontSize: '20px' }}>
                  {products[activeTab].price}
                </del>
              </Typography>
              <Divider sx={{ my: 3 }} />
              <Typography variant="h5" gutterBottom color="secondary" sx={{ fontWeight: 'normal', fontFamily: 'Adieu-Regular', mb: 2 }}>
                Overview
              </Typography>

              {activeDescription.map((point, index) => (
                // <li key={index} style={{lineHeight: '22px', marginTop: '10px'}}>{point}</li>
                <Typography variant="body1" gutterBottom color="secondary" sx={{ fontFamily: 'Product-Sans-Regular', pl: '20px', position: 'relative', color: 'primary.main' }}>
                  <span style={{ position: 'absolute', left: '-20px', top: '2px', fontSize: '20px' }}>•</span> {point}
                </Typography>
              ))}


              {/* <Typography variant="body1" gutterBottom color="secondary" sx={{fontFamily: 'Product-Sans-Regular', pl: '20px', position: 'relative', color:'primary.main'}}>
              <span style={{ position: 'absolute', left: '-20px', top: '2px', fontSize: '20px' }}>•</span> HDMI Input - Camera connects directly to Ninja Caster ensuring the highest quality of video.
            </Typography>
            
            <Typography variant="body1" gutterBottom color="secondary" sx={{fontFamily: 'Product-Sans-Regular', pl: '20px', position: 'relative', color:'primary.main'}}>
              <span style={{ position: 'absolute', left: '-20px', top: '2px', fontSize: '20px' }}>•</span> Wifi 6 / Sim card - Ensures full speed of hotspot of data connection is used to go live.
            </Typography> */}
              {/* <Box>            
              <InfoListItem label="Brand" content="Canon" />
              
              <InfoListItem label="Model Number" content="EOS1209y4574" />
              
              <InfoListItem label="Model Name" content="M50" />
              
              <InfoListItem label="Brand Color" content="Black and Gray" />
              
              <InfoListItem label="Type" content="Flip" />
              
              <InfoListItem label="Color" content="Black" />
              
              <InfoListItem label="Shooting Modules" 
                content="Scene Intelligent Auto, Hybrid Auto, Creative Assist,
                        SCN(Self-Portrait, Portrait, Smooth Skin, Landscape,
                        Sports, Close-up, Food, Panning, Handheld Night Scene,
                        HDR Backlight Control, Silent Mode), Creative Filters
                        (Grainy B/W, Soft Focus, Fish-eye Effect, Water painting
                        effect, Toy Camera Effect, Miniature Effect, HDR Art
                        Standard, Vivid, Bold, Embossed), Program AE, Shutter
                        Priority AE, Aperture Priority AE, Manual Exposure, Movie
                        (Movie Auto Exposure, Movie Manual Exposure, Time-
                        lapse Movie)" 
              />
              
              <InfoListItem label="Wifi" content="Yes" />
              
              <InfoListItem label="Image Processor" content="JPG, JPEG, PNG, MP4" />
              
              <InfoListItem label="Accessory Shoe" content="Hot Shoe" />
            </Box> */}
              <Divider />
              <ButtonOne text="Buy Now" onClick={() => navigateToURL(`/contact`)} style={{ marginTop: '24px', backgroundColor: "#FB4747", color: "#fff", height: "55px", }} />
            </Item>
          </Grid>
        </Grid>
      </Box>
    </>
  );
});

export default ProductContainer4;