import * as React from 'react';
import { BrowserRouter } from 'react-router-dom';
import { Routes, Route } from 'react-router-dom';
import { Home } from '../Home';
import { ProductDetails } from '../ProductDetails';
import { OrderDetails } from '../OrderDetails';
import {ContactUs} from '../ContactUs'
import { OrderNow } from '../OrderNow';

export const AppComponent = () => {
  return (
    <BrowserRouter>
        <Routes>
            <Route exact path="/" element={<Home />} />
            <Route exact path="/product" element={<ProductDetails />} />
            <Route exact path="/order" element={<OrderDetails />} />
            <Route exact path="/contact" element={<ContactUs />} />
            <Route exact path="/orders" element={<OrderNow />} />
        </Routes>
    </BrowserRouter>
  )
}
