import * as React from 'react';
import { styled, useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import { Typography } from '@mui/material';
import { ButtonOne } from '../../common/ButtonOne';
import { HeadingOne } from '../../common/HeadingOne';
import { useNavigate } from 'react-router-dom';

const Container = styled(Box)((props) => ({
  backgroundColor: props.theme.palette.secondary.main,
  color: props.theme.palette.white.main,
  marginTop: '20px',
}));

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.secondary.main,
  boxShadow: 'none',
}));

const CenteredImage = styled('img')(({ theme }) => ({
  width: 'auto', // Adjust the image size for larger screens
  height: 'auto', // Ensure the image maintains its aspect ratio
  marginTop: '15px',
  [theme.breakpoints.up('md')]: {
    maxHeight: '500px'
  },
  maxHeight: '400px'
}));

const InfoGridItem = ({ imageSrc,content }) => (
  <Grid item xs={12} sm={4} md={4}>
    <img src={imageSrc} />
    <Typography variant="body1" sx={{fontFamily: 'Product-Sans-Regular',}}>{content}</Typography>
  </Grid>
);

export const HomeContainer2 = () => {
  const navigate = useNavigate();

  const navigateToURL = (url) => {
    navigate(url, { replace: true });
  }

  const boxStyle = {
    background: 'radial-gradient(closest-side at 76% 50%, rgba(255, 167, 167, 1) 0%, rgba(251, 71, 71, 1) 100%) 0% 0%',    
    '@media (max-width: 540px)': {padding: '60px 0 0'},
  }

  return (
    <Box style={boxStyle} sx={{pt: 15, '@media (max-width: 540px)': {pt: 7, textAlign: 'center'}, '@media (max-width: 899px)': {background: '#fb4747 !important'}}}>
      <Grid container alignItems={'flex-end'} justifyContent={'space-between'} maxWidth={1170} marginX={'auto'} sx={{'@media (max-width: 1199px)': {px: 3}}}>
        <Grid item xs={12} md={7} mb={15} sx={{'@media (max-width: 540px)': {mb: 7}}}>
          <HeadingOne sx={{fontSize: '2.5rem',fontFamily: 'Adieu-Regular', color: '#fff', maxWidth: '550px',marginBottom: '40px', '@media (max-width: 540px)': {fontSize: '1.75rem'}}} text="Unlock the power of Ninja caster" />
          <Typography variant="body2" component="span" sx={{fontFamily: 'Product-Sans-Regular', color: '#fff', fontSize: '16px', lineHeight: '26px'}}>
            Introducing the new Ninja Caster, a game-changing sports streaming device developed in collaboration with CricClubs. This cutting-edge device allows you to effortlessly stream live sports events at high quality with ease. With its ready-to-stream capability in just a minute, the Ninja Caster eliminates the need for a computer, offering a completely wireless setup. Its compatibility with any camera gives you the freedom to capture every moment on the pitch up close with clarity and follow the ball.
          </Typography>

          <Box sx={{border: 'solid 1px #fff', padding: '30px', borderRadius: '10px', my: 5}}>
            <Typography variant="body2" component="span" sx={{color: '#fff', fontSize: '16px', lineHeight: '26px'}}>
              <Grid container sx={{ fontFamily: 'Product-Sans-Bold', '@media (max-width: 540px)': {textAlign: 'center'} }}>
                <Grid item xs={12} md={12} style={{marginBottom: "30px"}}>
                <Typography variant="body2" component="span" sx={{fontFamily: 'Product-Sans-Regular',fontSize: '20px', lineHeight: '30px', }}>
                  Start streaming in three easy steps:
                </Typography>
                </Grid>
              </Grid>
              <Grid container spacing={2} sx={{ textAlign: 'center' }}>
              <InfoGridItem
                imageSrc="./cctv.png"
                content="Connect Camera to Ninja Caster"
              />
              <InfoGridItem
                imageSrc="./layers.png"
                content="Select the game to stream"
              />
              <InfoGridItem
                imageSrc="./live.png"
                content="Start Live Streaming"
              />
              </Grid>
            </Typography>
          </Box>

          <Box sx={{ mt: 5 }}>
            <ButtonOne text="Contact Us" theme="invert" onClick={() => navigateToURL(`/contact`) } style={{height: '55px', background: '#fff', color: '#FB4747'}} />
          </Box>
        </Grid>
        <Grid item xs={12} md={3} sx={{textAlign: 'center','@media (max-width: 540px)': {padding: '0 70px'}}}>
          <img src="../Ninja-Caster-on-Tripod-1.png" style={{width: '100%', marginBottom: '-4px'}} />
        </Grid>
      </Grid>
    </Box>
  );
}
